export const formatProductStocks = (stocks: any) => {
  const data = [] as any;

  for (const stock of stocks) {

    const template = {
      id: stock.id,
      locationName: stock.location_name ?? null,
      locationId: stock.product_stock_location_id,
      quantity: Number(stock.quantity),
    }

    data.push(template)
  }

  return data;
};