import { createApp, markRaw } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import "@/assets/css/General.css"
import "@/assets/css/pos.css"
import "@/assets/css/loading.css"
import "@/assets/css/Button.css"
import "@/assets/css/Grid.css"
import "@/assets/css/Tabs.css"
import "@/assets/css/DetailList.css"
import "@/assets/css/Box.css"
import "@/assets/css/Loader.css"
import "@/assets/css/Icon.css"
import "@/assets/css/Badge.css"
import "@/assets/css/Select.css"
import "@/assets/css/Card.css"
import "@/assets/css/Notice.css"
import "@/assets/css/Component.css"
import "@/assets/css/Charts.css"
import "@/assets/css/VisuallyHidden.css"
import "@/assets/css/Radio.css"
import "@/assets/css/Chat.css"
import "@/assets/css/Search.css"
import "@/assets/css/Input.css"
import "@/assets/css/Layout.css"
import "@/assets/css/Label.css"
import "@/assets/css/SelectableCard.css"
import "@/assets/css/layout/Sidebar.css"

import "@/assets/css/SegmentControl/SegmentControl.css"

import "@/assets/css/modules/products/Stocks.css"
import "@/assets/css/modules/sales/Labels.css"
import "@/assets/css/modules/pos/General.css"
import "@/assets/css/modules/pos/Return.css"
import "@/assets/css/modules/purchases/Receipts.css"
import "@/assets/css/modules/picklists/Picklists.css"
import "@/assets/css//EmptyState.css"
import "@/assets/css//DisplayPlusMin.css"


import "@/assets/css/base/Icons.css"

import "@/assets/css/modules/tickets/General.css"
import "@/assets/css/Notification.css"

import "@/assets/css/compontent/Toolbar.css"
import "@/assets/css/Panel.css"
import "@/assets/css/Overlay.css"
import "@/assets/css/Base.css"
import "@/assets/css/layout/ContentToolbar.css"
import "@/assets/css/layout/Modal.css"
import "@/assets/css/layout/MenuScrollable.css"

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import stores from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
const pinia = createPinia();
import ApiService from "@/core/services/apiServicev3";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

//import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
//import "bootstrap";


declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: any
    vuex: any
    trans: any
  }
}

const app = createApp(App);

pinia.use(({ store }) => {
  store.router = markRaw(router)
  store.vuex = markRaw(stores)
  store.trans = markRaw(i18n.global);
})
app.use(pinia);
app.use(router);
app.use(stores);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");