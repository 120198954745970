export const formatStockLocations = (locations: any) => {
  const data = [] as any;

  for (const location of locations) {

    const template = {
      id: location.id,
      label: location.location_name ?? null,
      locationTypeId: parseFloat(location.location_type_id),
      stockId: parseFloat(location.product_stock_id),
    }

    data.push(template)
  }

  return data;
};