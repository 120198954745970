import { useProductStore } from "@/stores/products";

export const formatPicklistLines = async (lines: any) => {

  const productStore = useProductStore();

  const productIds = lines.map((line) => line.product_id);

  // Create the payload
  const payload = {
    sort: ["id:desc"],
    matchingStrategy: "all",
    facets: ["*"],
    filter: [
      [`id IN ${JSON.stringify(productIds)}`] // Include all product IDs in the filter
    ],
    offset: 0,
    limit: 1000,
  };

  // Fetch product data for all product IDs in one call
  // const productDataArray = await productStore.getProducts(payload);
  const [productData] = await Promise.all([
    productStore.getProducts(payload)
  ])

  // Create a map of productId to productData for quick lookup
  const productDataMap = productData.reduce((map, productData) => {
    map[productData.id] = productData;
    return map;
  }, {} as Record<string, any>);

  // Enrich the lines with product data
  return lines.map((line) => {
    const productData = productDataMap[line.product_id] || {};

    return {
      id: line.id,
      createdAt: line.created_at,
      createdBy: line.created_by,
      name: line.name,
      pickedQuantity: parseFloat(line.picked_quantity),
      quantity: parseFloat(line.quantity),
      barcodes: (productData.specifications || [])
        .find(spec => spec.name === 'EAN')?.options
        ?.map(option => option.name) || [],
      image: productData.media?.find(m => m.media_type === 'image')?.image_variations.small || null,
      productId: productData.id,
      locations: productData.products_stocks
        ?.map((stock) => stock.location_name) || [""]
    };
  });

};