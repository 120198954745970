export const extractValuesBasedOnFilterKey = (primaryData: any[], filterKey: string): any[] => {
  if (!primaryData || !filterKey) {
    throw new Error("Invalid arguments: both primaryData and filterKey are required.");
  }

  // Extract values based on the filterKey
  const extractedValues = primaryData
    .map((item) => item[filterKey]) // Extract the value for the given key
    .filter((value) => value !== undefined && value !== null); // Filter out undefined or null values
  return extractedValues;
};