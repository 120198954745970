import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";
import { nextTick } from "vue";

interface RootState {
  current: null | any
}

export const useProductsSuppliersStore = defineStore({
  id: "productsSuppliersStore",
  state: () =>
    ({
      current: null
    } as RootState),
  getters: {
  },
  actions: {
    async getSupplierRelations(supplierId: number, productIds: any) {
      const payload = {
        q: '',
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: [
          [`supplier_id=${supplierId}`],
          [`product_id IN ${JSON.stringify(productIds)}`]
        ],
        offset: 0,
        limit: 1000,
      };

      const { data } = await ApiService.post('apiUrlSearch', 'indexes/products_suppliers_relations/search', payload);

      return data.hits
    }
  }
});