import { useProductStore } from "@/stores/products";
import { useProductsSuppliersStore } from "@/stores/products/suppliers";
import { usePurchaseStore } from "@/stores/purchases/purchases";

export const formatReceiptLines = (lines: any) => {
  const productStore = useProductStore();
  const purchaseStore = usePurchaseStore();
  const productsSuppliersStore = useProductsSuppliersStore();

  const enrichLines = async (lines: any[]) => {
    // Extract all product IDs from lines
    const productIds = lines.map((line) => line.product_id);

    // Create the payload
    const payload = {
      sort: ["id:desc"],
      matchingStrategy: "all",
      facets: ["*"],
      filter: [
        [`id IN ${JSON.stringify(productIds)}`] // Include all product IDs in the filter
      ],
      offset: 0,
      limit: 1000,
    };

    // Fetch product data for all product IDs in one call
    // const productDataArray = await productStore.getProducts(payload);
    const [productData, supplierData] = await Promise.all([
      productStore.getProducts(payload),
      productsSuppliersStore.getSupplierRelations(purchaseStore.supplierId, productIds)
    ])

    // Create a map of productId to productData for quick lookup
    const productDataMap = productData.reduce((map, productData) => {
      map[productData.id] = productData;
      return map;
    }, {} as Record<string, any>);

    const supplierDataMap = supplierData.reduce((map, supplierData) => {
      map[supplierData.product_id] = supplierData;
      return map;
    }, {} as Record<string, any>);

    // Enrich the lines with product data
    return lines.map((line) => {
      const productData = productDataMap[line.product_id] || {};
      const supplierData = supplierDataMap[line.product_id] || {};
      return {
        id: line.id,
        productId: line.product_id,
        quantityExpected: Number(line.quantity_expected),
        quantityReceived: Number(line.quantity_received),
        logs: JSON.parse(line.logs),
        productTitle: productData.title || null,
        barcodes: (productData.specifications || [])
          .find(spec => spec.name === 'EAN')?.options
          ?.map(option => option.name) || [],
        supplierProductCode: supplierData.reference || productData.mpn, //productData.suppliers.find(s => s.id === purchaseStore.supplierId).
        image: productData.media.find(m => m.media_type === 'image')?.image_variations.small,
        receiptId: line.receipt_id
      };
    });

  };

  return enrichLines(lines)

};
