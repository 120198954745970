import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";
import { nextTick } from "vue";
import { formatStockLocations } from "../app/components/utils/stocks/formatStockLocations";
import { useAppStore } from "../app";

interface RootState {
  current: null | any
}

export const useStocksSearchStore = defineStore({
  id: "stocksSearchStore",
  state: () =>
  ({
    current: null
  } as RootState),
  getters: {
    totalQuantityReserved(state) {
      return state.current?.totals.reservedStock;
    },
    totalQuantityFreeStock(state) {
      return state.current?.totals.freeStock;
    }
  },
  actions: {
    async initProduct(item: any) {
      const template = {
        id: item.id,
        title: item.title,
        barcode: item.specifications.find(spec => spec.name === 'EAN')?.options ?? null,
        image: item.media.filter(item => item.media_type === 'image').map(item => item.file_full_url)[0],
        stockLocations: {
          totals: {
            quantity: 0,
            reserved: 0
          },
          locations: item.products_stocks?.map((location) => {
            return {
              id: location.id,
              label: location.location_name,
              status: location.location_status,
              quantity: Number(location.quantity),
              plusQuantity: null,
              type: location.stock_type,
              isActivated: false,
              isLoading: false,
            };
          }) ?? [],
        },
        activeInOrders: await this.salesLines(item.id),
        totals: {
          reservedStock: 0,
          freeStock: 0
        },
        isLoading: false,
      } as any

      this.current = template;

      this.calculateTotals();
    },
    async salesLines(productId: number) {

      const payload = {
        q: '',
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: [
          [`product_id=${productId}`],
          ['stock_reserved > 0']
          // ['sale_status != completed'],
          // ['sale_status != pending'],
          // ['sale_status != concept']
        ],
        offset: 0,
        limit: 1000,
      };

      const { data: lines } = await ApiService.post('apiUrlSearch', 'indexes/sales_lines/search', payload);

      const data = [] as any;
      for (const line of lines.hits) {
        const template = {
          productId: line.product_id,
          id: line.sale_id,
          quantity: Number(line.quantity),
          reserved: Number(line.stock_reserved),
          saleStatus: line.sale_status
        }
        data.push(template);
      }

      return data;
    },
    async updateStockLocationQuantity(location, searchInput) {
      const newQuantity = location.quantity + location.plusQuantity;

      try {
        this.current.isLoading = true;
        location.isLoading = true;

        const payload = {
          quantity: newQuantity
        }

        await ApiService.put('apiUrl', `products/stock/${location.id}`, payload);
      }
      finally {
        location.quantity = newQuantity;
        location.isActivated = false;
        location.plusQuantity = null;
        location.isLoading = false;
        this.current.isLoading = false;
        this.calculateTotals();

        nextTick(() => {
          searchInput.focus();
        })
      }
    },
    async updateStockQuantity(stockId: number, quantity: number) {
      const response = {
        status: 'error'
      }

      const payload = {
        quantity
      }

      const { data } = await ApiService.put('apiUrl', `products/stock/${stockId}`, payload);

      if (data.task_id) {
        response.status = 'success'
      }

      return response;
    },
    async calculateTotals() {
      this.current.stockLocations.totals.quantity = this.current.stockLocations.locations?.reduce((total, location) => total + Number(location.quantity), 0);
      this.current.totals.reservedStock = this.current.activeInOrders?.reduce((total, order) => total + Number(order.reserved), 0);
      this.current.totals.freeStock = this.current.stockLocations.totals.quantity - this.current.totals.reservedStock;
    },
    async getProductStock(productId: number) {
      const { data: stock } = await ApiService.get('apiUrlSearch', `indexes/products_stocks/documents/${productId}`);

      return stock
    },
    async getStockLocations(query: string, filter: any) {

      const response = {
        status: 'error',
        data: null
      }

      const payload = {
        q: query,
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: filter,
        offset: 0,
        limit: 20,
      };

      const { data: locations } = await ApiService.post('apiUrlSearch', 'indexes/products_stocks_locations/search', payload);

      if (locations.hits) {
        response.status = 'success'
        response.data = formatStockLocations(locations.hits);
      }

      return response;
    },
    async createStock(productId: number, quantity: number, stockType = 'intern', stockLocationId?: number) {
      const appStore = useAppStore();

      const response = {
        status: 'error',
        data: null
      }

      try {

        const payload = {
          product_id: productId,
          quantity: quantity,
          stock_type: stockType,
          ...(stockLocationId != null && { product_stock_location_id: stockLocationId })
        }

        const { data } = await ApiService.post('apiUrl', 'products/stock', payload);

        if (data) {
          const document = await appStore.validateDocument(data.item_id, 'products_stocks');

          if (document) {
            response.status = 'success'
            response.data = document
          }
        }

      }
      catch (e) {
        //
      }

      return response;
    },
    async updateStock(stockId: number, payload: any) {
      const appStore = useAppStore();

      const response = {
        status: 'error',
        data: null
      }

      try {

        const { data } = await ApiService.put('apiUrl', `products/stock/${stockId}`, payload);

        if (data.task_id) {
          // const document = await appStore.validateDocument(data.item_id, 'products_stocks');
          await appStore.taskStatus(data.task_id);

          // if (document) {
          response.status = 'success'
          //   response.data = document
          // }
        }

      }
      catch (e) {
        //
      }

      return response;
    }
  }
});