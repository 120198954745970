import { useProductStore } from "@/stores/products";
import { formatProductStocks } from "../stocks/formatProductStocks";

const enrichLines = async (lines: any) => {
  const productStore = useProductStore();
  const productIds = lines.map((line) => line.product_id);
  
  // Create the payload
  const payload = {
    sort: ["id:desc"],
    matchingStrategy: "all",
    facets: ["*"],
    filter: [
      [`id IN ${JSON.stringify(productIds)}`] // Include all product IDs in the filter
    ],
    offset: 0,
    limit: 1000,
  };

  const [productData] = await Promise.all([
    productStore.getProducts(payload),
    // productsSuppliersStore.getSupplierRelations(purchaseStore.supplierId, productIds)
  ])

  return productData

}

export const formatPurchaseLines = async (lines) => {
  const productStore = useProductStore();

  const enrichedLines = await enrichLines(lines);

  const productDataMap = enrichedLines.reduce((map, productData) => {
    map[productData.id] = productData;
    return map;
  }, {} as Record<string, any>);

  const data = [] as any;

  for (const line of lines) {
    const productData = productDataMap[line.product_id] || {};
    const quantity = Number(line.quantity);
    const totalAmount = Number(line.purchase_price)

    const template = {
      image: productData.media.find(m => m.media_type === 'image')?.image_variations.small,
      productId: line.product_id,
      name: line.name,
      price: totalAmount / quantity,
      total: totalAmount,
      quantity: quantity,
      stockLocations: formatProductStocks(productData.products_stocks)
    }

    data.push(template)
  }

  return data;

};
