export const formatSelectOptions = (data) => {

  const formattedData = [] as any;

  for (const option of data) {
    const template = {
      label: option.label,
      value: option.id
    }

    formattedData.push(template)
  }

  return formattedData;

};