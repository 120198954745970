import { collectTargetKeyValues } from "./collectTargetKeyValues";
import { mergeData } from "./mergeData";

export const processRelatedIndexes = (data, relatedResults) => {
  relatedResults.forEach(result => {
    // Determine which action to take based on the mergeStrategy
    if (result.index.mergeStrategy === 'merge') {
      // Call mergeData if mergeStrategy is 'merge'
      data = mergeData(data, relatedResults); // Return adjusted data after merging
    } else {
      data.forEach(dataItem => {
        // Voor elke key in mergeFields, verzamel de opties en voeg ze toe aan dataItem
        result.index.mergeFields.forEach(fieldConfig => {
          const collectedOptions = collectTargetKeyValues(dataItem, result, fieldConfig.targetKey);

          // Voeg de verzamelde opties toe als een nieuwe key in dataItem
          dataItem[fieldConfig.creationKey] = collectedOptions;
        });
      });
    }
  });

  return data; // Return the adjusted data after processing all relatedResults
};