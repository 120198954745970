import { useProductStore } from "@/stores/products";
import { formatProductStocks } from "../stocks/formatProductStocks";

export const formatPurchaseReceipts = async (receipts, lines) => {
  const productStore = useProductStore();

  receipts.forEach(receipt => {
    // Filter de lines die horen bij het huidige receipt
    const enrichedLines = lines.filter(line => line.receiptId === receipt.id);

    // Bereken de totals
    receipt.totals = {
      quantityProducts: enrichedLines.length, // Aantal lines
      quantityExpected: enrichedLines.reduce((total, line) => total + (line.quantityExpected || 0), 0),
      quantityReceived: enrichedLines.reduce((total, line) => total + (line.quantityReceived || 0), 0),
    };

    // Voeg de verrijkte lines toe aan het receipt
    receipt.lines = enrichedLines;
  });

  return receipts;
};
