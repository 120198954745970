export const formatPicklist = (picklist: any) => {
  
  const template = {
    id: picklist.id,
    progress: picklist.lines_processed,
    processedBy: picklist.processed_by_name,
    status: picklist.picklist_status,
    pdf: picklist.picklist_url,
    amount: picklist.picklist_value,
    saleId: picklist.sale_id,
    createdAt: picklist.created_at,
    createdBy: picklist.created_by,
    customerId: picklist.customer_id,
    customerName: picklist.customer_name,
    lines: [],
  }

  return template;
};