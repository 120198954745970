import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";
import { formatPurchaseLines } from "../app/components/utils/purchases/formatPurchaseLines";

interface RootState {
  current: any
  lines: any
  isLoading: boolean
}

export const usePurchaseStore = defineStore({
  id: "purchaseStore",
  state: () =>
  ({
    current: null,
    lines: [],
    isLoading: false,
  } as RootState),
  getters: {
    supplierId(state) {
      return state.current?.supplier_id;
    },
    purchaseId(state) {
      return state.current?.id;
    }
  },
  actions: {
    async getPurchaseProductSupplierPrice(productId: number, supplierId: number) {
      const response = {
        status: 'error',
        data: null
      } as any

      try {
        const payload = {
          sort: ['id:desc'],
          matchingStrategy: 'all',
          facets: ['*'],
          filter: [
            [`supplier_id=${supplierId}`],
            [`product_id=${productId}`]
          ],
          offset: 0,
          limit: 20
        };

        const { data } = await ApiService.post('apiUrlSearch', `/indexes/products_suppliers_relations/search`, payload);

        if (data.hits.length) {
          response.status = 'success'
          response.data = data.hits[0];
        }
      } catch (error) {
        console.error(error);
      }

      return response;
    },
    async getPurchase(purchaseId: number) {
      const { data } = await ApiService.get('apiUrlSearch', `/indexes/products_suppliers_purchases/documents/${purchaseId}`);

      if (data) {
        this.current = data;
      }

    },
    async getPurchaseLines(purchaseId: number) {

      const payload = {
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: [
          [`purchase_id=${purchaseId}`]
        ],
        offset: 0,
        limit: 20
      };

      const { data } = await ApiService.post('apiUrlSearch', `/indexes/products_suppliers_purchases_lines/search`, payload);

      console.log(data.hits)
      this.lines = await formatPurchaseLines(data.hits);


    }
  }
});