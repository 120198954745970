import { defineStore } from "pinia";
import axios from "axios"
import { useMenuStore } from "@/stores/menus";
import { useToolbarStore } from "@/stores/toolbar";
import useInit from '@/composables/v2/useInit';
import { useAppStore } from "./app";
import ApiService from "@/core/services/apiServicev3";
import { useContentSummaryStore } from "./content/summary";

interface RootState {
  submenu: any | null
  toolbar: any | null
  breadcrumb: {
    title: string | null
  }
  init: any
  data: any | null
  isOverlay: boolean
}

export const useContentStore = defineStore({
  id: "contentStore",
  state: () =>
    ({
      submenu: null,
      toolbar: null,
      breadcrumb: {
        title: null
      },
      init: false,
      data: null,
      isOverlay: false,
    } as RootState),
  getters: {
    initState(state) {
      return state.init;
    },
    routeData(state) {
      return state.data;
    },
    productTitle(state) {
      return state.data?.title
    },
    breadcrumbTitle: (state) => {
      return state.breadcrumb.title;
    }
  },
  actions: {
    async initContent(init: any, submenu: any, toolbarSettings: any, summary: any) {
      const { toolbar } = useInit();
      const menuStore = useMenuStore();
      const toolbarStore = useToolbarStore();
      const contentSummaryStore = useContentSummaryStore();
      this.init = false;

      try {
        await this.contentData(init);
        await contentSummaryStore.initContentSummary(summary)
        if (init.title) {
          this.setBreadcrumbTitle(this.trans.t(`titles.${init.title.sectionTitle}`) + ` - ${init.currentId} - ${this.routeData[init.title.routeData]}`)
        }
        await toolbarStore.initToolbar(toolbarSettings);
        await menuStore.generateSubmenu(submenu.name, this.data);
      }
      catch(error) {
        //
      }
      finally {
        this.init = true;
      }
    },
    async contentData(init) {
      const appStore = useAppStore();
      try {
        const { data } = await ApiService.get('apiUrlSearch', `/indexes/${init.dispatchAction}/documents/${init.currentId}`);
        this.data = data
      }
      catch(err) {
        //console.log(err)
      }
      
      finally {
        //
      }
    },
    async setBreadcrumbTitle(title: string) {
      this.breadcrumb.title = title;
    },
    resetStore() {
      const toolbarStore = useToolbarStore();
      this.$reset();
      toolbarStore.resetToolbarStore();
    }
  }
});