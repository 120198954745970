import ApiService from "@/core/services/apiServicev3";
import { defineStore } from "pinia";
import { formatPicklist } from "../app/components/utils/picklists/formatPicklist";
import { formatPicklistLines } from "../app/components/utils/picklists/formatPicklistLines";
import { useContentNotificationsStore } from "../content/notifications";

interface RootState {
  current: any
  pickableLines: any
  isLoading: boolean
}

export const usePicklistStore = defineStore({
  id: "picklistStore",
  state: () =>
  ({
    current: { lines: [] },
    pickableLines: [],
    isLoading: false
  } as RootState),
  getters: {
    totalQuantityPickingLines(state) {
      return state.pickableLines.reduce((total, line) => {
        return total + Math.abs(line.pickQuantity);
      }, 0);
    },
    totalQuantityPickableLines(state) {
      return state.pickableLines.length;
    },
    totalQuantityPickableItems(state) {
      return state.pickableLines.reduce((total, line) => {
        return total + Math.abs(line.quantity);
      }, 0);
    },
    totalQuantityPickedItems(state) {
      return state.current.lines.reduce((total, line) => {
        return total + Math.abs(line.pickedQuantity);
      }, 0);
    },
    totalQuantityItems(state) {
      return state.current.lines.reduce((total, line) => {
        return total + Math.abs(line.quantity);
      }, 0);
    },
    currentPicklistId(state) {
      return state.current?.id;
    },
    picklistStatus(state) {
      return state.current?.status;
    },
    processedBy(state) {
      return state.current?.processedBy;
    }
  },
  actions: {
    async getPicklist(picklistId: number) {

      const response = {
        status: 'error',
        data: null
      } as any

      const { data: picklist } = await ApiService.get('apiUrlSearch', `indexes/picklists/documents/${picklistId}`);

      if (picklist) {
        response.status = 'success'
        response.data = formatPicklist(picklist)
      }

      return response;
    },
    async getPicklistLines(picklistId: number) {

      const response = {
        status: 'error',
        data: null
      }

      const payload = {
        sort: ['id:desc'],
        matchingStrategy: 'all',
        facets: ['*'],
        filter: [
          [`picklist_id=${picklistId}`]
        ],
        offset: 0,
        limit: 1000,
      };

      const { data: picklistLines } = await ApiService.post('apiUrlSearch', 'indexes/picklists_lines/search', payload);

      if (picklistLines.hits) {
        response.status = 'success'
        response.data = await formatPicklistLines(picklistLines.hits)
      }

      return response;
    },
    async updatePicklistLine(picklistLineId: number, payload: any) {
      const response = {
        status: 'error',
      }

      try {

        const { data } = await ApiService.put('apiUrl', `picklists/line/${picklistLineId}`, payload);

        if (data.task_id) {
          response.status = 'success'
        }

      } catch (e) {
        //
      }

      return response;
    },
    async pickAllLines() {
      const contentNotificationsStore = useContentNotificationsStore();
      const updatePromises = this.current.lines.map(async (line) => {
        const oldQuantity = line.pickedQuantity; // Sla de oude waarde op
        line.pickedQuantity = line.quantity;

        try {
          // Probeer de lijn bij te werken
          const result = await this.updatePicklistLine(line.id, {
            picked_quantity: line.pickedQuantity,
          });

          if (result.status !== 'success') {
            console.error(`Failed to update line with id: ${line.id}`);
            line.pickedQuantity = oldQuantity;
          }

          return result;
        } catch (error) {
          console.error(`Error updating line with id: ${line.id}`, error);
          line.pickedQuantity = oldQuantity;
          return { status: 'error', error };
        }
      });

      try {
        this.isLoading = true;
        const results = await Promise.all(updatePromises);

        const failedUpdates = results.filter(result => result.status !== 'success');
        if (failedUpdates.length > 0) {
          contentNotificationsStore.setNotification(
            'titles.Failed',
            'phrases.processPickAllLinesFailed',
            'error'
          );
        } else {

          contentNotificationsStore.setNotification(
            'titles.Success',
            'phrases.processPickAllLinesSuccess',
            'success'
          );
        }
      } catch (error) {
        console.error("An unexpected error occurred during the update process:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async closePicklist() {
      const contentNotificationsStore = useContentNotificationsStore();
      try {
        this.isLoading = true;
        const { status } = await this.updatePicklist(this.currentPicklistId, { picklist_status: 'completed' });

        if (status === 'success') {
          contentNotificationsStore.setNotification(
            'titles.Success',
            'phrases.processClosePicklistSuccess',
            'success'
          );

          this.current.status = 'completed'
        } else {
          contentNotificationsStore.setNotification(
            'titles.Failed',
            'phrases.processClosePicklistFailed',
            'error'
          );
        }
      }
      catch (e) {
        //
      }
      finally {
        this.isLoading = false;
      }
    },
    async updatePicklist(picklistId: number, payload: any) {
      const response = {
        status: 'error',
      }

      const { data } = await ApiService.put('apiUrl', `picklist/${picklistId}`, payload);

      if (data.task_id) {
        response.status = 'success'
      }

      return response;
    },
    async getPickableLines(saleLines: any) {
      const list = [] as any

      for (const line of saleLines) {
        const lineQuantity = parseFloat(line.quantity);
        const pickedQuantity = parseFloat(line.picklist_quantity);
        const totalInclVat = parseFloat(line.net_total_with_vat);

        if (lineQuantity !== pickedQuantity) {

          const item = {
            id: line.id,
            productId: line.product_id,
            name: line.name,
            quantity: lineQuantity,
            pickedQuantity: pickedQuantity,
            price: totalInclVat / lineQuantity,
            pickQuantity: lineQuantity - pickedQuantity,
            range: { min: -pickedQuantity, max: lineQuantity - pickedQuantity },
          }

          list.push(item);
        }
      }

      this.pickableLines = list;
    },
    formatPickableLines(lines) {
      return lines
        .filter(line => line.pickQuantity !== 0)
        .map(line => ({ id: line.id, quantity: `${line.pickQuantity}` }));
    },
  }
});