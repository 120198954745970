import config from "@/core/config/App";
import { defineStore } from "pinia";

type SummaryItem = {
  label: string;
  value: string | number;
  type: 'text'
}

interface RootState {
  items: SummaryItem[];
}

export const useContentSummaryStore = defineStore({
  id: "contentSummaryStore",
  state: () =>
  ({
    items: [],
  } as RootState),
  getters: {
    // modalStatus(state) {
    //   return state.init;
    // },
  },
  actions: {
    async initContentSummary(summary: any) {
      if (summary.state) {

        const template = config.content.summary[summary.name];

        if (template) {
          this.items = template;
        }
      } else {
        this.$reset();
      }
    }
  }
});