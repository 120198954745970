import { defineStore } from "pinia";
import { useContentStore } from "@/stores/content";
import { useAppStore } from "@/stores/app";
import { useSalesStore } from "@/stores/sales/sales";
import { useAppComponentsStore } from "@/stores/app/components/components";
import { formatContentActions } from "@/utils/formatContentActionsTemplate";
import ApiService from "@/core/services/apiServicev3";
import { usePOSStore } from "@/views/pos/stores/pos";
import { Modal } from "bootstrap";
import { nextTick } from "vue";
import { useWebshopStore } from "@/stores/webshop/webshop";
import { useCartStore } from "@/views/pos/stores/cart";
import { useContentModalsStore } from "@/stores/content/modals";
import { useContentNotificationsStore } from "./content/notifications";
import { routeChildren } from "@/utils/useIsRouteChildren";
import { useRoute } from "vue-router";

interface RootState {
  current: any
  action: any
  showModal: boolean
  refreshKey: number
  modal: {
    currentId: string | null
    isActive: boolean
    refreshKey: number
  }
  isLoading: boolean
}

export const useToolbarStore = defineStore({
  id: "toolbarStore",
  state: () =>
  ({
    current: { actions: {}, uid: null, routeId: null, state: false, dispatchAction: null },
    action: null,
    modal: {
      currentId: null,
      isActive: false,
      refreshKey: 0
    },
    showModal: false,
    refreshKey: 0,
    isLoading: false,
  } as RootState),
  getters: {
    refreshAmount(state) {
      return state.refreshKey;
    },
    toolbarActions(state) {
      return state.current.actions;
    },
    currentToolbar(state) {
      return state.current;
    },
    contentUid(state) {
      return state.current.uid;
    },
    contentRouteId(state) {
      return state.current.routeId;
    },
    sourceNameActions(state) {
      return state.current.source;
    },
    modalState: (state) => {
      return state.modal.isActive;
    },
    modalRefreshKey: (state) => {
      return state.modal.refreshKey;
    },
    modalId: (state) => {
      return state.modal.currentId;
    },
    currentAction: (state) => {
      return state.action;
    }
  },
  actions: {
    async initToolbar(settings) {
      const contentStore = useContentStore();
      const appStore = useAppStore();
      console.log(settings)
      if (settings.init) {
        const { init, name, currentData, currentId } = settings,
          actions = appStore.getConfig('actions.' + name),
          uid = contentStore.routeData?.uid ?? null,
          routeId = Number(currentId),
          dataAction = currentData

        this.current.source = name;
        this.current.actions = formatContentActions(actions);
        this.current.uid = uid;
        this.current.routeId = routeId;
        this.current.state = init;
        this.current.currentData = dataAction;
      } else {
        this.$reset();
      }
    },
    async processToolbarAction(action) {
      const salesStore = useSalesStore();
      const contentStore = useContentStore();
      const appComponentStore = useAppComponentsStore();
      const posStore = usePOSStore();
      const appStore = useAppStore();
      const webshopStore = useWebshopStore();
      const cartStore = useCartStore();
      const contentModalsStore = useContentModalsStore();
      const contentNotificationsStore = useContentNotificationsStore();

      const { method, name } = action;
      this.action = action;

      try {
        action.isLoading = true;

        const uid = contentStore.routeData.uid;

        if (method === 'applyDiscount') {
          const id = contentStore.routeData.id;
          const customerId = contentStore.routeData.customer_id;

          const settings = {
            'sales': { lines: 'saleLines', endpoint: '/sales/line' },
            'sales/quotations': { lines: 'quotationLines', endpoint: '/sales/quotations/line' },
          }

          const lines = await salesStore[settings[this.sourceNameActions].lines](id);

          if (lines.length) {
            await salesStore.applyDiscounts(lines, customerId, settings[this.sourceNameActions].endpoint);
            await appComponentStore.reloadAllComponents();
          }

        } else if (method === "posApplyDiscount") {
          await cartStore.applyDiscounts();
        } else if (method === 'pdfConfirmation') {

          console.log(this.current)

          const endpoint = {
            'sales': `/api/sale/pdf/confirmation/${this.contentUid}`,
            'sales/quotations': `/api/sale/pdf/quotation/${this.contentUid}`,
            'products/purchases': `/api/products/suppliers/purchase/pdf/confirmation/${this.contentUid}`,
            'products/purchases/receipts': `/api/products/suppliers/receipt/pdf/confirmation/${this.contentUid}`,
            'picklists': `/api/picklist/pdf/${uid}`,
            STOCK_LOCATION_LABEL: `/api/products/stocks/locations/label/pdf/${this.contentUid}`
          }

          if (endpoint[this.sourceNameActions]) {
            window.open(appStore.appUrl + endpoint[this.sourceNameActions], "_blank");
          }
        } else if (method === 'pdf') {
          const endpoint = {
            'picklists': `/api/picklist/pdf/${this.contentUid}`,
            'sales/invoices': `/api/sales/invoice/pdf/${this.contentUid}`,
            'products/stocks/locations': `/api/products/stocks/locations/label/pdf/${this.contentUid}`
          }

          if (endpoint[this.sourceNameActions]) {
            window.open(appStore.appUrl + endpoint[this.sourceNameActions], "_blank");
          }
        } else if (method === 'delete') {

          const endpoint = {
            'sales': { source: '/sales', redirect: { state: true, source: '/sales' } },
            'sales/quotations': { source: '/sales/quotations', redirect: { state: true, source: '/sales/quotations' } },
            'customers': { source: '/customers', redirect: { state: true, source: '/customers' } },
            'customers/discounts': { source: '/customers/discounts', redirect: { state: true, source: '/customers/discounts' } },
            'customers/subscriptions': { source: '/customers/subscriptions', redirect: { state: true, source: '/customers/subscriptions' } },
            'products': { source: '/products', redirect: { state: true, source: '/products' } },
            'products/specifications': { source: '/products/specifications', redirect: { state: true, source: '/products/specifications' } },
            'products/suppliers': { source: '/products/suppliers', redirect: { state: true, source: '/products/suppliers' } },
            'products/stocks/locations': { source: '/products/stocks/locations', redirect: { state: true, source: '/products/stocks/locations' } },
            'picklists': { source: '/picklists', redirect: { state: true, source: '/picklists' } },
            'pos/checkout': { source: '/sales', redirect: { state: true, source: `/pos/${posStore.currentPOSId}/checkout` } },
            'products/suppliers/purchases': { source: '/products/suppliers/purchases', redirect: { state: true, source: `/products/purchases` } },
            'products/suppliers/receipts': { source: '/products/suppliers/receipts', redirect: { state: true, source: `/products/purchases/${contentStore.routeData.id}` } },
          }

          const { data } = await ApiService.delete('apiUrl', endpoint[this.sourceNameActions].source, { id: this.contentRouteId });

          this.router.push({ path: endpoint[this.sourceNameActions].redirect.source });
        } else if (method === 'openInPos') {
          this.router.push({ path: '/pos/' + posStore.currentPOSId + '/checkout/' + this.contentRouteId });
        } else if (method === "accounting") {
          contentModalsStore.openModal('es_modal_toolbar_accounting', { type: "modal", custom: true });

        } else if (method === "convert") {
          const saleId = await salesStore.convertToSale(this.contentRouteId);

          if (saleId) {
            this.router.push({ path: `/sales/${saleId}` })
          }

        } else if (method === 'emailSaleConfirmation' || method === 'emailQuotationConfirmation' || method === 'emailPurchaseConfirmation' || method === 'emailInvoiceConfirmation') {

          contentModalsStore.openModal('es_modal_toolbar_sent_email', { type: "modal", custom: true, data: method });
        } else if (method === 'rebuildWebshop') {
          await webshopStore.rebuildWebshop(this.contentRouteId);
        } else if (method === 'newSale') {
          const { data } = await posStore.createSale();
          const document = await posStore.validateDocument(data.id, 'sales');

          if (document && document.id) {
            this.router.push({ path: '/pos/' + posStore.currentPOSId + '/checkout/' + data.id });
          }
        } else if (method === "lockUser") {
          await posStore.lockUser();
        } else if (method === "cashierLogOut") {
          const posStore = usePOSStore();
          const id = contentStore.routeData.id;
          const { status } = await posStore.unlockPos(id);

          if (status === 'success') {
            contentNotificationsStore.setNotification('Succesvol',  `Kassamedewerker succes uitgelogd`, 'success');
          } else {
            contentNotificationsStore.setNotification('Fout', 'Er is geen kassamedewerker ingelogd.', 'error');
          }
        } else if (method === "processEditMode") {
          // TODO: Make it dynamic. Can be created by adding an extra field as action setting.
          this.router.push({ path:`${this.router.currentRoute.value.path}/${this.router.currentRoute.value.matched[1].children[0].path}` })
        } else if (method === "processViewMode") {
          // TODO: Make it dynamic. Can be created by adding an extra field as action setting.
          const path = this.router.currentRoute.value.path;
          this.router.push({ path: path.substring(0, path.lastIndexOf("/")) });
        }
      }
      catch (error: any) {
        console.log(error)
        contentNotificationsStore.setNotification('Fout', error.response.data.result.errors, 'error');
      }
      finally {
        action.isLoading = false;
      }
    },
    resetToolbarStore() {
      this.$reset();
    },
    async sentEmail(data, modalId: string) {
      console.log(data)
      this.currentAction.isLoading = true;

      const contentModalsStore = useContentModalsStore();
      const contentNotificationsStore = useContentNotificationsStore();

      try {
        const contentStore = useContentStore();
        const settings = {
          'sales': '/sale/email',
          'sales/quotations': '/sales/quotation/email',
          'sales/invoices': '/sales/invoice/email',
          'products/purchases': '/products/suppliers/purchase/email'
        }

        const payload = { id: contentStore.routeData.id, email: data }
        const { data: actionResponse } = await ApiService.post('apiUrl', settings[this.sourceNameActions], payload);

        if (actionResponse.code === 200) {
          contentModalsStore.closeModal(modalId);
          contentNotificationsStore.setNotification('Succesvol', `E-mail is succesvol verstuurd naar ${data}`, 'success')
        } else {
          contentNotificationsStore.setNotification('Fout', `E-mail is niet succesvol gestuurd naar ${data}`, 'error')
        }
      }
      catch (error: any) {
        const errorResponse = error.response.data.result[0].email;
        contentNotificationsStore.setNotification('Fout', `${errorResponse}`, 'error');
      }
      finally {
        this.currentAction.isLoading = false;
      }
    }
  }
});