import ApiService from "@/core/services/apiServicev3";
import { formatReceiptLines } from "./formatReceiptLines";
import { useProductStore } from "@/stores/products";

export const formatReceipts = async (receipts: any, lines = []) => {
  // Process a single receipt
  const processReceipt = async (receipt: any) => {
    const enrichedLines = await formatReceiptLines(lines)

    return {
      id: receipt.id,
      purchaseId: receipt.purchase_id,
      status: receipt.receipt_status,
      createdAt: receipt.created_at,
      createdBy: receipt.created_by,
      lines: enrichedLines, //enrichedLines,
      totals: {
        quantityProducts: enrichedLines.length, // enrichedLines.length,
        quantityExpected: enrichedLines.reduce((total, line) => total + line.quantityExpected, 0),
        quantityReceived: enrichedLines.reduce((total, line) => total + line.quantityReceived, 0),
      },
    };
  };

  // Handle input type (array or single object)
  if (Array.isArray(receipts)) {
    return Promise.all(receipts.map(processReceipt));
  } else if (typeof receipts === "object" && receipts !== null) {
    return processReceipt(receipts);
  } else {
    throw new Error("Invalid input: receipts must be an object or an array of objects");
  }
};