import { defineStore } from "pinia";
import ApiService from "@/core/services/apiServicev3";
import { formatPurchaseLines } from "../app/components/utils/purchases/formatPurchaseLines";
import { formatReceipts } from "../app/components/utils/purchases/formatReceipts";
import { formatReceiptLines } from "../app/components/utils/purchases/formatReceiptLines";
import { useAppStore } from "../app";
import { useProductStore } from "../products";
import { useStocksSearchStore } from "../products/stocks";
import { useContentNotificationsStore } from "../content/notifications";
import { formatPurchaseReceipts } from "../app/components/utils/purchases/formatPurchaseReceipts";
import { usePurchaseStore } from "./purchases";
import { formatSelectOptions } from "../app/components/utils/formatSelectOptions";

interface RootState {
  data: any
  current: any
  stockLocations: any
  isLoading: boolean
}

export const usePurchaseReceiptsStore = defineStore({
  id: "purchaseReceiptsStore",
  state: () =>
  ({
    current: null,
    data: [],
    stockLocations: [],
    isLoading: false,
  } as RootState),
  getters: {
    receiptId(state) {
      return state.current?.id;
    },
    receiptStatus(state) {
      return state.current?.status;
    },
    totalQuantityOrdered(state) {
      return state.current?.lines.reduce((total, line) => {
        return total + Math.abs(line.quantityOrdered);
      }, 0);
    },
    totalQuantityReceived(state) {
      return state.current?.lines.reduce((total, line) => {
        return total + Math.abs(line.quantityReceived);
      }, 0);
    },
    totalQuantityExpected(state) {
      return state.current?.lines.reduce((total, line) => {
        return total + Math.abs(line.quantityExpected);
      }, 0);
    },
  },
  actions: {
    async createReceipt(purchaseId: number) {
      const contentNotificationsStore = useContentNotificationsStore();
      const appStore = useAppStore();

      try {
        const payload = {
          purchase_id: purchaseId
        }

        const { data } = await ApiService.post('apiUrl', '/products/suppliers/receipt', payload);

        if (data.item_id) {
          await appStore.validateDocument(data.item_id, 'products_suppliers_receipts');
          this.router.replace({ path: `/products/purchases/${purchaseId}/receipts/${data.item_id}` });
          contentNotificationsStore.setNotification('titles.Success', 'phrases.createPurchaseReceiptSuccess', 'success')
        } else {
          contentNotificationsStore.setNotification('titles.Failed', 'phrases.createPurchaseReceiptFailed', 'error')
        }
      }
      catch (err) {
        contentNotificationsStore.setNotification('titles.Failed', 'phrases.createPurchaseReceiptNoLinesAvailableFailed', 'error')
      }
      finally {
        //
      }
    },
    async getReceipt(receiptId: number) {
      const purchaseStore = usePurchaseStore();
      const [receipt, lines] = await Promise.all([
        ApiService.get('apiUrlSearch', `/indexes/products_suppliers_receipts/documents/${receiptId}`),
        this.getReceiptLines(receiptId)
      ]);

      this.current = await formatReceipts(receipt.data, lines);
    },
    async getReceiptLines(receiptId: number) {
      const payload = {
        sort: ["id:desc"],
        matchingStrategy: "all",
        facets: ["*"],
        filter: [
          [`receipt_id=${receiptId}`]
        ],
        offset: 0,
        limit: 1000,
      };

      const { data } = await ApiService.post('apiUrlSearch', `/indexes/products_suppliers_receipts_lines/search`, payload);

      return data.hits;
    },
    async getReceiptsLines(receiptIds: any) {

      const response = {
        status: 'error',
        data: null
      } as any

      const payload = {
        sort: ["id:desc"],
        matchingStrategy: "all",
        facets: ["*"],
        filter: [
          [`receipt_id IN ${JSON.stringify(receiptIds)}`]
        ],
        offset: 0,
        limit: 1000,
      };

      const { data } = await ApiService.post('apiUrlSearch', `/indexes/products_suppliers_receipts_lines/search`, payload);

      if (data.hits) {
        response.status = 'success'
        response.data = await formatReceiptLines(data.hits)
      }

      return response;
    },
    async getReceipts(purchaseId: number) {

      const response = {
        status: 'error',
        data: null
      } as any

      const payload = {
        sort: ["id:desc"],
        matchingStrategy: "all",
        facets: ["*"],
        filter: [
          [`purchase_id=${purchaseId}`]
        ],
        offset: 0,
        limit: 1000,
      };

      const { data } = await ApiService.post('apiUrlSearch', `/indexes/products_suppliers_receipts/search`, payload);

      if (data.hits) {
        const formattedData = await formatReceipts(data.hits, []);

        response.status = 'success'
        response.data = formattedData
      }

      return response;
    },
    async updateReceiptLine(lineId: number, payload: any) {
      const appStore = useAppStore();

      const response = {
        status: 'error'
      }

      const { data } = await ApiService.put('apiUrl', `/products/suppliers/receipts/line/${lineId}`, payload);

      if (data.task_id) {
        await appStore.taskStatus(data.task_id);
        // this.getReceipt(this.current.id);
        response.status = 'success';
      }

      return response;
    },
    async cancelReceiptLineLog(line: any, log: any) {
      const contentNotificationsStore = useContentNotificationsStore();
      const productStockStore = useStocksSearchStore();

      this.isLoading = true;

      try {

        const newQuantity = line.quantityReceived - log.quantity

        const { status } = await this.updateReceiptLine(line.id, { log_uid: log.uid, cancel: true, quantity_received: newQuantity });

        if (status === 'success') {
          line.quantityReceived = newQuantity;
          const stock = await productStockStore.getProductStock(log.stock_id);

          if (stock) {
            const previousStockQuantity = stock.quantity - log.quantity;
            const { status } = await productStockStore.updateStockQuantity(log.stock_id, previousStockQuantity);

            if (status === 'success') {
              contentNotificationsStore.setNotification('titles.Success', 'phrases.cancelReceiptLineLogSuccess', 'success')
            } else {
              contentNotificationsStore.setNotification('titles.Failed', 'phrases.cancelReceiptLineLogFailed', 'error')
            }
          }
        } else {
          contentNotificationsStore.setNotification('titles.Failed', 'phrases.cancelReceiptLineLogNoLongerLinked', 'error')
        }
      }
      catch (e) {
        contentNotificationsStore.setNotification('titles.Failed', 'phrases.cancelReceiptLineLogNoLongerLinked', 'error')
      }
      finally {
        this.isLoading = false;
      }
    },
    async updateReceipt(receiptId: number, payload: any) {
      const response = {
        status: 'error'
      }

      const { data } = await ApiService.put('apiUrl', `/products/suppliers/receipt/${receiptId}`, payload);

      if (data.task_id) {
        response.status = 'success';
      }

      return response;
    },
    async roundReceiptOff(receiptId: number) {
      const contentNotificationsStore = useContentNotificationsStore();
      try {
        this.isLoading = true;

        const { status } = await this.updateReceipt(receiptId, { receipt_status: 'completed' })

        if (status === 'success') {
          this.current.status = 'completed'
          contentNotificationsStore.setNotification('titles.Success', 'phrases.roundReceiptOffSuccess', 'success')
        } else {
          contentNotificationsStore.setNotification('titles.Failed', 'phrases.roundReceiptOffFailed', 'error')
        }

      }
      catch {
        contentNotificationsStore.setNotification('titles.Failed', 'phrases.roundReceiptOffFailed', 'error')
      }
      finally {
        this.isLoading = false;
      }
    },
    async getPurchaseReceipts(purchaseId: number) {
      const { status: receiptStatus, data: receipts } = await this.getReceipts(purchaseId);

      if (receiptStatus === 'success' && receipts.length) {
        const receiptIds = receipts.map((receipt) => receipt.id);
        const { data: receiptsLines } = await this.getReceiptsLines(receiptIds);

        const combinedReceipts = await formatPurchaseReceipts(receipts, receiptsLines);

        this.data = combinedReceipts
      }
    },
    async searchStockLocations(query: string) {
      const stocksSearchStore = useStocksSearchStore();

      const filter = [
        ['location_status=1'],
        ['product_stock_id IS NULL']
      ];

      const { status, data } = await stocksSearchStore.getStockLocations(query, filter);

      if (status === 'success') {
        this.stockLocations = formatSelectOptions(data)
      }
    },
  }
});