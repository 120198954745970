const config = {
  taskTotalChecks: 60,
  tasksCheckTimer: 300,
  timerTableSearch: 250,
  documentTotalChecks: 20,
  documentCheckTimer: 500,
  app: {
    displayCurrency: "EUR",
    displayPriceDecimals: 2,
    displayLocale: 'nl-NL',
  },
  endpoints: {
    pdf: {
      'sales': '/api/sale/pdf/confirmation',
      'sales/quotations': '/api/sale/pdf/quotation',
      'products/suppliers/purchases': '/api/products/suppliers/purchase/pdf/confirmation',
      'sales/invoices': '/api/sales/invoice/pdf',
      'sales/receipts': '/api/sales/invoice/pdf'
    }
  },
  content: {
    summary: {
      sales: [
        { label: 'Kanaal', type: 'text', value: 'channel_type' },
        { label: 'Status', type: 'badge', value: 'sale_status' },
        { label: 'Klant', type: 'text', value: 'customer_id' },
        { label: 'Lijnen', type: 'badge', value: 'channel_type' },
        { label: 'Aantal te factureren', type: 'badge', value: 'channel_type' },
        { label: 'Totaalbedrag', type: 'badge', value: 'channel_type' }
      ]
    }
  }
}

export default config;
